var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("h2", { staticClass: "font-semibold" }, [
          _vm._v(
            _vm._s(
              _vm.$t(_vm.resources.CustomizedCommissions.i18n) ||
                _vm.resources.CustomizedCommissions.name
            )
          )
        ]),
        _c("h5", { staticClass: "font-semibold mt-4 mb-2" }, [
          _vm._v(
            _vm._s(
              _vm.$t(_vm.alerts.MsgConsiderInformation.i18n) ||
                _vm.alerts.MsgConsiderInformation.i18n
            )
          )
        ]),
        _c("p", [
          _vm._v(
            "- " +
              _vm._s(
                _vm.$t(_vm.alerts.MsgPayTransaction.i18n) ||
                  _vm.alerts.MsgPayTransaction.i18n
              ) +
              "."
          )
        ]),
        _c("p", [
          _vm._v(
            "- " +
              _vm._s(
                _vm.$t(_vm.alerts.MsgDaysToPayment.i18n) ||
                  _vm.alerts.MsgDaysToPayment.i18n
              )
          )
        ]),
        _c("p", [
          _vm._v(
            "- " +
              _vm._s(
                _vm.$t(_vm.alerts.MsgNotEnoughMoney.i18n) ||
                  _vm.alerts.MsgNotEnoughMoney.i18n
              )
          )
        ]),
        _c("p", [
          _vm._v(
            "- " +
              _vm._s(
                _vm.$t(_vm.alerts.MsgStageBill.i18n) ||
                  _vm.alerts.MsgStageBill.i18n
              ) +
              "."
          )
        ]),
        _c("br"),
        _vm.pendingValue < -1
          ? _c("h4", { staticClass: "text-center text-danger" }, [
              _vm._v(
                "USD $" +
                  _vm._s(_vm.pendingValue) +
                  " " +
                  _vm._s(
                    _vm.$t(_vm.alerts.MsgTotalPendingCharges.i18n) ||
                      _vm.alerts.MsgTotalPendingCharges.i18n
                  ) +
                  "."
              )
            ])
          : _vm._e(),
        _c("p", { staticClass: "text-center font-semibold" }, [
          _vm._v(
            _vm._s(
              _vm.$t(_vm.alerts.MsgWriteToCustomerService.i18n) ||
                _vm.alerts.MsgWriteToCustomerService.i18n
            ) + "."
          )
        ]),
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/2 mb-base mt-6" },
            [
              _c(
                "vx-card",
                {
                  staticClass: "text-center bg-primary-gradient greet-user",
                  attrs: { slot: "no-body" },
                  slot: "no-body"
                },
                [
                  _c("img", {
                    staticClass: "decore-left",
                    attrs: {
                      src: require("@/assets/images/elements/decore-left.png"),
                      alt: "Decore Left",
                      width: "150"
                    }
                  }),
                  _vm.total > 0
                    ? _c("feather-icon", {
                        staticClass:
                          "p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow",
                        attrs: { icon: "AwardIcon", svgClasses: "h-8 w-8" }
                      })
                    : _c("vs-icon", {
                        attrs: {
                          "icon-pack": "material-icons",
                          icon: "sentiment_very_dissatisfied",
                          size: "large",
                          color: "rgb(255,255,255)"
                        }
                      }),
                  _vm.total > 0
                    ? _c("h1", { staticClass: "mb-4 text-white" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Congratulations.i18n) ||
                              _vm.resources.Congratulations.name
                          ) +
                            " " +
                            _vm._s(_vm.username) +
                            ","
                        )
                      ])
                    : _c("h1", { staticClass: "mb-6 text-white" }, [
                        _vm._v(_vm._s(_vm.username) + ",")
                      ]),
                  _c(
                    "p",
                    {
                      staticClass:
                        "xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(_vm.alerts.MsgYouHaveEarned.i18n) ||
                              _vm.alerts.MsgYouHaveEarned.name
                          ) +
                          "\n            "
                      ),
                      _c("strong", [_vm._v("USD $" + _vm._s(_vm.total))]),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(_vm.alerts.MsgTipAndFee.i18n) ||
                              _vm.alerts.MsgTipAndFee.name
                          ) +
                          ".\n          "
                      )
                    ]
                  ),
                  _vm.total < 2
                    ? _c("p", { staticClass: "text-white" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.alerts.MsgNotEnoughMoney.i18n) ||
                              _vm.alerts.MsgNotEnoughMoney.i18n
                          )
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm.notTransactions
                ? _c("div", [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "font-semibold text-center mt-6 text-danger"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.alerts.MsgOneTransferAtDay.i18n) ||
                              _vm.alerts.MsgOneTransferAtDay.name
                          ) + "."
                        )
                      ]
                    )
                  ])
                : _vm.currentDate.getHours() >= 13 &&
                  _vm.currentDate.getDay() == 3
                ? _c("div", [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "font-semibold text-center mt-6 text-danger"
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.alerts.MsgNotPayment.i18n) ||
                              _vm.alerts.MsgNotPayment.name
                          ) + "."
                        )
                      ]
                    )
                  ])
                : _c(
                    "div",
                    { staticClass: "vx-col w-full mt-5 text-center" },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: {
                            color: "primary",
                            size: "large",
                            type: "filled",
                            disabled: !_vm.validateForm
                          },
                          on: {
                            click: function($event) {
                              return _vm.payPayout(3)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Transfer.i18n) ||
                                _vm.resources.Transfer.name
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full lg:w-1/2 mb-base mt-6" },
            [
              _c("h4", { staticClass: "font-semibold text-center mt-6" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.TipAndCustomizedFee.i18n) ||
                      _vm.resources.TipAndCustomizedFee.name
                  )
                )
              ]),
              _c("vs-input", {
                staticClass: "w-full font-semibold mb-base",
                attrs: {
                  type: "number",
                  "icon-pack": "feather",
                  icon: "icon-dollar-sign",
                  "icon-no-border": "",
                  disabled: "",
                  label:
                    _vm.$t(_vm.resources.TipsTotal.i18n) ||
                    _vm.resources.TipsTotal.name
                },
                model: {
                  value: _vm.tipTotal,
                  callback: function($$v) {
                    _vm.tipTotal = $$v
                  },
                  expression: "tipTotal"
                }
              }),
              _c("vs-input", {
                staticClass: "w-full font-semibold mb-base",
                attrs: {
                  type: "number",
                  "icon-pack": "feather",
                  icon: "icon-dollar-sign",
                  "icon-no-border": "",
                  disabled: "",
                  label:
                    _vm.$t(_vm.resources.FeeTotal.i18n) ||
                    _vm.resources.FeeTotal.name
                },
                model: {
                  value: _vm.feeTotal,
                  callback: function($$v) {
                    _vm.feeTotal = $$v
                  },
                  expression: "feeTotal"
                }
              }),
              _c("vs-input", {
                staticClass: "w-full font-semibold mb-base",
                attrs: {
                  type: "number",
                  "icon-pack": "feather",
                  icon: "icon-dollar-sign",
                  "icon-no-border": "",
                  disabled: "",
                  label:
                    _vm.$t(_vm.resources.TotalPendingCharges.i18n) ||
                    _vm.resources.TotalPendingCharges.name
                },
                model: {
                  value: _vm.pendingValue,
                  callback: function($$v) {
                    _vm.pendingValue = $$v
                  },
                  expression: "pendingValue"
                }
              }),
              _c(
                "div",
                { staticClass: "vx-col w-full mt-5 text-center" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: {
                        to: "/commissions/DetailCustomized",
                        color: "primary",
                        size: "large",
                        type: "filled"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.TotalTransactions.i18n) ||
                            _vm.resources.TotalTransactions.name
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }